export default {
    events: {
        url: 'https://events.idox.io',
        method: 'POST',
        route: '/events'
    },
    form: {
        url: 'https://api-beta.idox.com.br',
        method: 'GET',
        route: '/interviews'
    },
    save: {
        url: 'https://api-beta.idox.com.br',
        method: 'POST',
        route: '/interviews'
    },
    chat: {
        url: 'https://chat.idox.io',
        method: 'WEBSOCKT'
    },
    files: {
        url: 'https://api.idox.io',
        method: 'GET',
        route: '/upload/s3_access_token'
    }
}
